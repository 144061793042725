<script setup lang="ts">
import { ParagraphListing } from '~/api/graphql/generated'

useHead({
  bodyAttrs: {
    class: 'is-listing',
  },
})

const props = defineProps<{
  entity: ParagraphListing
}>()

useNoCache()

const names = {
  news: 'NewsListing',
  event: 'EventsListing',
  conference_event: 'EventsListingConference',
  info_event: 'EventsListingInfo',
  employees: 'EmployeesListing',
  practicas: 'PracticasListing',
  tocco: 'ToccoListing',
}
const name = names[props.entity.fieldListing as keyof typeof names] || names.news
</script>

<template>
  <component :is="name" />
</template>
<style scoped lang="scss"></style>
